import { Link } from "gatsby";
import _ from "lodash";
import React from "react";

export default function Tags({ categories }: { categories: string[] }) {
  if (categories?.length > 0) {
    return (
      <span>
        {" in "}
        {categories.map((c) => (
          <Link
            to={`/categories/${_.kebabCase(c)}`}
            className="btn btn-sm py-0 btn-secondary me-1"
            role="button"
            style={{ textTransform: "uppercase", verticalAlign: "0.1em" }}
            key={c}
          >
            {c}
          </Link>
        ))}
      </span>
    );
  }

  return <span />;
}
